import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AssetSvg } from '../../assets';
import {
  AppConstants,
  AppRouteUi,
  CallType,
  DefaultImageType,
  FileBucketURL,
  CallStatusType,
} from '../../configs';
import { device } from '../../configs/twilioDevice';
import {
  ActionVideoCall,
  CallContext,
  SocketContext,
  StorageAuth,
  useAppSelector,
} from '../../core';
import { CallDurationCounter, ModalIvrCustomField } from '../../features';
import { ActionAudioCall } from '../../core/Redux/Slices/sliceCall';
import { useFunctionalityCalls } from '../hooks/functionalityCalls';
import {
  useFunctionalityGetCustomInput,
  useFunctionalityImageView,
} from '../hooks';
import { ModalHangupCall } from '../../features/modals/common/hangupCallModal';
import useFunctionalityMediaPermissions from '../hooks/mediaPermission/functionalityMediaPermission';
import { ITypeCustomInput } from '../../models';
import { ModalIvrOutboundCallRedial } from '../../features/modals/common/ivrOutboundCallRedialModal';
import { NetworkConnection } from '../../components';
import { MediaPermission } from '../../utils';

export function PageIncomingCall(): JSX.Element {
  console.log("hello, this is Ghadir from IncomingCallPage BBB");
  const history = useHistory();
  const [isAudioEnabled, setAudioEnabled] = useState(true);
  const { callinfo, isAccepted, setIsAccepted } = useContext(CallContext);
  const { socketConnection } = useContext(SocketContext);
  const dispatch = useDispatch();
  const data = useAppSelector((state) => ({
    audioCallStatus: state.audioCall.audioCallStatus,
    userRole: state.auth.profile?.userRole,
    sessionId: state.auth.sessionId,
    isBackdatedVersion: state.common.isBackdatedVersion,
  }));
  const isIVRCall = (() => {
    const callType = callinfo?.customParameters?.get('Type');
    return callType === 'IVR' || callType === 'ThreeWayIVR';
  })();

  const [show, setShow] = useState(false);
  const [showOutboundModal, setShowOutboundModal] = useState(false);
  const [showIvrCustomFieldModal, setShowIvrCustomFieldModal] = useState(false);
  const { onUpdate, onComplete, onStartInitiate, onOutboundRedial } =
    useFunctionalityCalls();
  const audioCallStatusRef = React.useRef(data.audioCallStatus);
  audioCallStatusRef.current = data.audioCallStatus;
  const interval = useRef<any>(null);
  
  const homePage = (() => {
    if (StorageAuth.ProfileData?.userRole === AppConstants.UserRoles.Operator) {
      return AppRouteUi.CallLog.Root;
    } else {
      return AppRouteUi.Home.Root;
    }
  })();
  !callinfo?.customParameters && history.push(homePage);
  const isCalleeOperator = callinfo?.customParameters?.get('CalleeRole') === AppConstants.UserRoles.Operator;

  useEffect(() => {
    if (isAccepted) {
      dispatch(ActionAudioCall.SetAudioCallStartTime(new Date().toString()));
    }
  }, [isAccepted]);
  useEffect(() => {
    !isIVRCall && onUpdate(callinfo.customParameters?.get('CallId'), {
      callStatus: CallStatusType.Ringing,
      callType: CallType.Audio,
      eventTime: new Date().toISOString(),
      isCallStarted: false,
      callerUserId: callinfo.customParameters?.get('CallerUid'),
    });
  }, []);

  const { stopBothVideoAndAudio } = useFunctionalityMediaPermissions();
  const outBoundMessageMapping = (callStatusType?: CallStatusType) => {
    let message = '';
    switch (callStatusType) {
      case CallStatusType.OutboundCallCompleted:
        message = 'Outbound call completed';
        break;
      case CallStatusType.OutboundCallInitiated:
        message = 'Making outbound call';
        break;
      case CallStatusType.OutboundCallNotAnswered:
        message = 'No answer';
        break;
      case CallStatusType.OutboundCallReceived:
        message = 'Connected with outbound caller';
        break;
      case CallStatusType.OutboundCallRinging:
        message = 'Ringing for outbound caller';
        break;
      case CallStatusType.OutboundCallNotReceived:
        message = 'Outbound call is not received';
        break;
    }
    return message;
  };
  useEffect(() => {
    interval.current = setTimeout(
      (function (x) {
        return function () {
          if (
            audioCallStatusRef?.current?.current === CallStatusType.Ringing &&
            audioCallStatusRef?.current?.prev === CallStatusType.Initiated
          ) {
            callinfo.reject();

            onUpdate(callinfo.customParameters?.get('CallId'), {
              callType: CallType.Audio,
              callStatus: CallStatusType.Missed,
              eventTime: new Date().toISOString(),
              isCallStarted: false,
            });
            history.push({
              pathname:
                data.userRole === AppConstants.UserRoles.Operator
                  ? AppRouteUi.CallLog.Root
                  : AppRouteUi.Home.Root,
            });
          }
        };
      })(data),
      40000
    );

    return () => {
      clearTimeout(interval.current);
    };
  }, []);
  const audioCallCompleted = () => {
    dispatch(
      ActionAudioCall.SetAudioCallStatus({
        prev: CallStatusType.Established,
        current: CallStatusType.Completed,
      })
    );
    setIsAccepted(false);
    device.disconnectAll();
  };
  const audioCallRejected = (socketConnection: any) => {
    clearTimeout(interval.current);
    const callerId = callinfo.customParameters?.get('CallerUid');
    const callid = callinfo.customParameters?.get('CallId');
    dispatch(
      ActionVideoCall.SetCallStatus({
        prev: CallStatusType.Ringing,
        current: CallStatusType.Rejected,
      })
    );
    !isIVRCall && onUpdate(callid, {
      callStatus: CallStatusType.Rejected,
      eventTime: new Date().toISOString(),
      isCallStarted: false,
    });
    stopBothVideoAndAudio();
    history.push({
      pathname:
        data.userRole === AppConstants.UserRoles.Operator
          ? AppRouteUi.CallLog.Root
          : AppRouteUi.Home.Root,
    });
  };
  const [participantState, setParticipantState] = React.useState({
    prev: 0,
    current: 1,
  });
  const { defaultImageLoad, imageView } = useFunctionalityImageView();

  const [getCustomInputData, setGetCustomInputData] = useState<
    ITypeCustomInput[]
  >([]);
  const { onCustomInputData } = useFunctionalityGetCustomInput(
    callinfo.customParameters?.get('CallerUid')
  );


  const OnReceivedPermissionHandler = async () => {
    clearTimeout(interval.current);
    if (await MediaPermission.getStatus({ audio: true })) {
      dispatch(
        ActionVideoCall.SetCallStatus({
          prev: CallStatusType.Ringing,
          current: CallStatusType.Received,
        })
      );
      callinfo.accept();
      if (
        callinfo?.customParameters?.get('Type') === 'IVR' ||
        callinfo?.customParameters?.get('Type') === 'ThreeWayIVR'
      ) {
        // show modal
        onCustomInputData()?.then((res) => {
          if (res?.data?.length) {
            setShowIvrCustomFieldModal(true);
            setGetCustomInputData(res.data);
          } else {
            setShowIvrCustomFieldModal(false);
          }
        });
        if (callinfo?.customParameters?.get('Type') === 'ThreeWayIVR') {
          onStartInitiate(
            callinfo?.customParameters?.get('CallSid'),
            data.sessionId ?? '00-00-00'
          );
        }
      }
    } else {
      callRejectHandler();
      history.push({
        pathname: AppRouteUi.OutgoingCallFailedForMic.Root,
      });
    }
  };

  const callRejectHandler = () => {
    dispatch(
      ActionAudioCall.SetAudioCallStatus({
        prev: CallStatusType.Ringing,
        current: CallStatusType.Rejected,
      })
    );

    callinfo.reject();
    //device.disconnectAll();
    audioCallRejected(socketConnection); //for rejecting incoming call
  };
  useEffect(() => {
    if (
      data.audioCallStatus?.current == CallStatusType.Received ||
      data.audioCallStatus?.current == CallStatusType.Rejected
    ) {
      clearTimeout(interval.current);
    }
    if (
      data.audioCallStatus?.current == CallStatusType.OutboundCallNotAnswered
    ) {
      setShowOutboundModal(true);
    }
  }, [data?.audioCallStatus?.current]);
  useEffect(() => {
    if (
      data.audioCallStatus?.current == CallStatusType.Received ||
      data.audioCallStatus?.current == CallStatusType.Rejected
    ) {
      clearTimeout(interval.current);
    }
  }, [data?.audioCallStatus?.current]);
  const onEndedPlay = (ev: any) => {
    ev.target.currentTime = 0;
    ev.target.play();
  };

  useEffect(() => {
    if (data.audioCallStatus?.current === CallStatusType.Ringing) {
      try {
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function (event) {
          window.history.pushState(null, document.title, window.location.href);
        });
      } catch (err) {
        console.error(err);
      }
    }
  }, [data.audioCallStatus?.current]);
  useEffect(() => {
    if (data.audioCallStatus?.current === CallStatusType.Ringing) {
      window.onbeforeunload = function () {
        return true;
      };
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [data.audioCallStatus?.current]);

  useEffect(() => {
    if (!isIVRCall) {
      return;
    }
    const handleCallCancel = () => {
      callinfo?.reject();
      history.push({
        pathname: homePage,
      });
    };

    callinfo?.on('cancel', handleCallCancel);

    return () => {
      callinfo?.removeListener('cancel', handleCallCancel);
    };
  }, [callinfo, isIVRCall, history, homePage]);

  useEffect(() => {
    return () => {
      callinfo?.reject?.();
    };
  }, [callinfo]);

  return (
    <>
      <div className="site-call-page ">
        <div className="site-call-page-wrapper">
          <div className="container-fluid h-100">
            <div className="row  justify-content-center align-items-center h-100 Page-call">

              <div className="col-6 text-center d-flex align-items-center justify-content-center pt-4 pb-4">
                <ul className=" babelCallAnimation bg-white caller-image">
                  <img
                    src={`${FileBucketURL.Root}${callinfo.customParameters?.get(
                      'CallerUid'
                    )}?${imageView.fileTimeStamp}`}
                    alt=""
                    onError={(ev) => {
                      defaultImageLoad(
                        ev,
                        DefaultImageType.UserProfile,
                        '?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
                      );
                    }}
                    className="img-fluid"
                  />
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </div>
              {data.audioCallStatus?.current == CallStatusType.Completed && (
                <div className="col-12 text-center  text-white p-3">
                  <h5 className="m-0">
                    Preparing Call Summary. Please wait...
                  </h5>
                </div>
              )}
              {data.audioCallStatus?.current !== CallStatusType.Completed &&
                data.audioCallStatus?.prev !== CallStatusType.Established && (
                  <>
                    <div className="col-12 text-center text-white p-3">
                      {isAccepted ? (
                        <p className="mb-3 pb-3 h5">
                          <CallDurationCounter
                            participantState={participantState}
                          />
                        </p>
                      ) : (
                        <p className="mb-3 pb-3 h5">Incoming OPI call</p>
                      )}
                      {data.audioCallStatus?.current ==
                        CallStatusType.OutboundCallInitiated ||
                        data.audioCallStatus?.current ==
                          CallStatusType.OutboundCallNotAnswered ||
                        data.audioCallStatus?.current ==
                          CallStatusType.OutboundCallRinging ||
                        data.audioCallStatus?.current ==
                          CallStatusType.OutboundCallCompleted ||
                        data.audioCallStatus?.current ==
                          CallStatusType.OutboundCallReceived}
                      <h4 className="m-0">
                        {outBoundMessageMapping(data.audioCallStatus?.current)}
                      </h4>
                      <h2 className="pb-4">
                        {callinfo.customParameters?.get('CallerName')}
                      </h2>
                      {
                        !isCalleeOperator && (
                          <h5 className="m-0">
                            { callinfo.customParameters?.get('CallerCompanyName')}
                          </h5>
                        )
                      }
                      <h5 className="m-0">
                        {' '}
                        {callinfo.customParameters?.get('OperatorLanguageName')}
                      </h5>
                    </div>
                    <div className="h-15 w-100">
                      <NetworkConnection hangupHandler={audioCallCompleted} />
                    </div>
                    {isAccepted ? (
                      <div className="col-12 text-center p-3">
                        
                        <div className="d-flex align-items-center justify-content-center container">
                          <button
                            className="btn  mx-4  callPage-controlBtn btn-red"
                            role="button"
                            onClick={() => {
                              setShow(true);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path d="M511.2 387l-23.25 100.8c-3.266 14.25-15.79 24.22-30.46 24.22C205.2 512 0 306.8 0 54.5c0-14.66 9.969-27.2 24.22-30.45l100.8-23.25C139.7-2.602 154.7 5.018 160.8 18.92l46.52 108.5c5.438 12.78 1.77 27.67-8.98 36.45L144.5 207.1c33.98 69.22 90.26 125.5 159.5 159.5l44.08-53.8c8.688-10.78 23.69-14.51 36.47-8.975l108.5 46.51C506.1 357.2 514.6 372.4 511.2 387z" />
                            </svg>
                          </button>
                          <ModalHangupCall
                            handleShow={setShow}
                            onSubmit={() => audioCallCompleted()}
                            show={show}
                            header={'Hangup Call'}
                          />
                          {isAudioEnabled ? (
                            <button
                              type="button"
                              className="btn mx-4 callPage-controlBtn btn-green btn-audio-on"
                              onClick={() => {
                                setAudioEnabled(false);
                                device.activeConnection()?.mute(true);
                              }}
                            >
                              <AssetSvg.AudioOn />
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn mx-4 callPage-controlBtn btn-red btn-audio-off"
                              onClick={() => {
                                setAudioEnabled(true);
                                device.activeConnection()?.mute(false);
                              }}
                            >
                              <AssetSvg.AudioOff />
                            </button>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="col-12 text-center p-3">
                        <div className="d-flex align-items-center justify-content-center container">
                          <button
                            className="btn mx-4  callPage-controlBtn btn-red"
                            role="button"
                            onClick={() => {
                              callRejectHandler();
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path d="M511.2 387l-23.25 100.8c-3.266 14.25-15.79 24.22-30.46 24.22C205.2 512 0 306.8 0 54.5c0-14.66 9.969-27.2 24.22-30.45l100.8-23.25C139.7-2.602 154.7 5.018 160.8 18.92l46.52 108.5c5.438 12.78 1.77 27.67-8.98 36.45L144.5 207.1c33.98 69.22 90.26 125.5 159.5 159.5l44.08-53.8c8.688-10.78 23.69-14.51 36.47-8.975l108.5 46.51C506.1 357.2 514.6 372.4 511.2 387z" />
                            </svg>
                          </button>

                          <button
                            className="btn mx-4  callPage-controlBtn btn-green"
                            onClick={() => {
                              OnReceivedPermissionHandler();
                            }}
                            disabled={data.isBackdatedVersion}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path d="M511.2 387l-23.25 100.8c-3.266 14.25-15.79 24.22-30.46 24.22C205.2 512 0 306.8 0 54.5c0-14.66 9.969-27.2 24.22-30.45l100.8-23.25C139.7-2.602 154.7 5.018 160.8 18.92l46.52 108.5c5.438 12.78 1.77 27.67-8.98 36.45L144.5 207.1c33.98 69.22 90.26 125.5 159.5 159.5l44.08-53.8c8.688-10.78 23.69-14.51 36.47-8.975l108.5 46.51C506.1 357.2 514.6 372.4 511.2 387z" />
                            </svg>
                          </button>
                          
                        </div>
                      </div>
                    )}
                  </>
                )}
            </div>
          </div>
        </div>
        <h3> Hello, I am IncomingCallPage</h3>
      </div>
      {showIvrCustomFieldModal && (
        <ModalIvrCustomField
          show={showIvrCustomFieldModal}
          handleShow={setShowIvrCustomFieldModal}
          callId={callinfo.customParameters?.get('CallId')}
          callerId={callinfo.customParameters?.get('CallerUid')}
          callerCompanyId={callinfo.customParameters?.get('CallerCompanyId')}
          getCustomInputData={getCustomInputData}
        />
      )}
      <ModalIvrOutboundCallRedial
        handleShow={setShowOutboundModal}
        show={showOutboundModal}
        callinfo={callinfo}
        onOutboundRedial={onOutboundRedial}
      />
    </>
  );
}
